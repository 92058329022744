.App {
  text-align: center;
}

.hide-overflow-y{
   overflow-y: hidden;
}

.overlay-div{
   z-index: 5;
   position: absolute;
}

.image-container-fill{
   background-image: url('https://i.imgur.com/yB4EGz1.jpg');
   background-size: cover;
   height: 400px;
   width: 25vw;
}

@media screen and (max-width: 600px){
   .image-container-fill{
      width: 45vw;
   }
}

.full-screen{
   height: 100vh;
   max-width: 100%;
   width: 100%;
}

.hide-overflow-x{
   overflow-x: hidden;
}

.thick-characters{
   font-size: 2.5rem;
   font-weight: 700 !important;
   color: white;
}

.margin-auto{
   margin: auto;
}

.center-text {
   display: flex !important;
   justify-content: center;
   align-items: flex-start;
}
