/* Thanks Brandon Lee! */

.portfolio-item-description {
	-webkit-transition: all 0.25s ease-in-out 0s;
   -moz-transition: all 0.25s ease-in-out 0s;
   -ms-transition: all 0.25s ease-in-out 0s;
   -o-transition: all 0.25s ease-in-out 0s;
   transition: all 0.25s ease-in-out 0s;
}

.portfolio-item-preview {
	-webkit-transition: all 0.3s ease-in-out 0s;
   -moz-transition: all 0.3s ease-in-out 0s;
   -ms-transition: all 0.3s ease-in-out 0s;
   -o-transition: all 0.3s ease-in-out 0s;
   transition: all 0.3s ease-in-out 0s;
}

/* .portfolio-item:hover .portfolio-item-preview { 
   -webkit-transform: scale(1.1); 
    -moz-transform: scale(1.3); 
    -ms-transform: scale(1.3); 
    -o-transform: scale(1.3); 
    transform: scale(1.2); 
 }  */

/* ---------------------------------------------- /*
 * Portfolio
/* ---------------------------------------------- */

.portfolio-item {
	margin: 20px;
}

.portfolio-item-preview {
	position: relative;
}


.portfolio-item:hover .portfolio-item-description {
	opacity: 1;
	bottom: 0;
}

.portfolio-item-description {
	background: rgba(255, 255, 255, .85);
   position: absolute;
   overflow: auto;
	right: 0;
   bottom: 0px;
   max-width: 100%;
   width: 100%;
	left: 0;
	text-align: center;
	opacity: 0;
	color: #222;
}

.dHLACw{
   overflow: visible !important;
}